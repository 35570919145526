footer {
  background-color: black;
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.footer__logo {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
  color: white;
}