#apodr {
  background: linear-gradient(to right, #D2C1D3, #F3C5CB);
}

#apodcs {
  background: linear-gradient(to right, #E3E7E1, #E4E3DB);
}

#apodr li {
  font-size: 1.3rem;
}

.final_results {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
}

.final_results img {
  max-width: 100%;
  max-height: 100%;
  width: 30%;
}

#apodcs li a {
  font-size: 1.3rem;
}

@media (max-width: 768px){
  #apodr h2 {
    padding: 0 20px;
  }

  #apodr li {
    font-size: 1rem;
  }
}