#skills {
  background: linear-gradient(to right, #F7DCC9, #F7B0B0);
}

.skills_list {
  max-width: 1000px;
}

.skills_header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.skills_header_container *{
  margin-right: 20px;
}

.skills_list_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.skill_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 10px;
  padding: 10px;
}

.lightbulb {
  width: 2rem;
  height: auto;
}

.skill_item_info {
  display: flex;
  flex-direction: column;
  line-height: 150%;
}

.skill_item_name {
  font-size: 1.2rem;
}

.skill_item_level {
  font-size: 0.9rem;
  font-weight: lighter;
}

@media (max-width: 768px) {
  .skills_list {
    padding: 0 20px;
    margin: 0 20px;
  }

  .skills_list_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .sm {
    display: none;
  }
}


@media (max-width: 300px) {
  .skills_list_container {
    grid-template-columns: repeat(1, 1fr);
  }
}

