body {
  margin: 0;
  font-family: 'Georgia', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 100vw;
}

#root {
  width: 100vw;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  color: rgb(21, 102, 105);
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.flex_container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  gap: 10rem;
  padding: 6rem 0;
}

.flex_container_has_title {
  padding: 0 0 6rem 0;
}

.flex_container_rows {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  gap: 10rem;
  padding: 6rem 0;
  flex-direction: row;
}

html {
  scroll-behavior: smooth;
}

.btn {
  background-color: #F0CDFF;
  color: rgb(21, 102, 105);
  border: 1px solid rgb(21, 102, 105);
  border-radius: 5px;
  padding: 15px 50px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  width: fit-content;
}

.info_box_container {
  display: flex;
  flex-direction: column;
}

.info_box_container a {
  margin-top: -20px;
  align-self: center;
}

.info_box {
  background-color: #EEECDE;
  width: fit-content;
  height: fit-content;
  padding: 20px 50px;
  line-height: 200%;
  border-radius: 5px;
  border-color: rgb(21, 102, 105);
  border-width: 2px;
  border-style: solid;
}

.info_box h1 {
  font-size: 2rem;
  margin: 0;
}

.info_box h2 {
  font-size: 4rem;
}

.info_box p {
  font-size: 1.2rem;
}

.info_box ul {
  font-size: 1.2rem;
  margin-left: -50px;
}

.info_box_text {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 120%;
}

.info_box_pic_title_text {
  display: grid !important;
  padding-top: 0;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info_box_pic_title_text img {
  width: 60px;
  height: auto;
}

.info_box_pic_title_text .info_box_text {
  font-weight: normal;
}

.info_box_pic_title_text .title_image_text {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info_box_pic_title_text h1 {
  height: 100px;
  display: flex;
  align-items: center;
}

.slide_title {
  font-size: 3rem;
  text-align: center;
  padding: 1rem 10% 0 10%;
}

.info_banner {
  background-color: #EEECDE;
  display: flex;
  justify-content: center;
  width: 100%;
  border-style: solid;
  border-color: rgb(21, 102, 105);
  border-width: 1px;
  padding: 20px 0;
  font-size: 1.7rem;
}

.info_banner_text {
  width: 70%;
  text-align: center;
}

/* Carousel */
.carousel .slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .slide img {
  max-width: 500px;
  max-height: 100%;
  justify-self: center;
  align-self: center;
}

.carousel .thumbs {
  display: flex;
}

.carousel-root {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.thumbs-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbs {
  padding-inline-start: 0;
}

/* Mobile */
@media (max-width: 768px) {
  .flex_container {
    flex-direction: column;
    padding-top: 0;
    gap: 5rem;
  }

  .slide_title {
    font-size: 2rem;
  }

  .info_banner {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  /* Carousel */
  .carousel-root {
    width: 300px;
  }

}