#gsapp {
  background: linear-gradient(to right, #A7A3CE, #DE89A6);
}

#gsapp-posts {
  background: linear-gradient(to right, #D7C7B7, #B7D5C9);
  padding: 5rem 0;
}

#gsapp li {
  font-size: 1.3rem;
}

#gsapp .flex_container_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

#gsapp p {
  font-size: 2rem;
}

#gsapp .flex_container {
  gap: 10px;

}

.flex_container_row.gsapp_ads h2 {
  text-align: center;
}

.posts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
}

.posts img {
  width: 500px;
  height: auto;
  border-radius: 40px;
}

@media (min-width: 768px) {
  #gsapp .flex_container_row h2 {
    font-size: 2.5rem;
  }
  
  #gsapp .flex_container_row h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  #gsapp .flex_container_row {
    padding: 0 20px;
  }

  .posts {
    flex-direction: column;
  }

  .posts img {
    width: 90vw;
    height: auto;
    padding: 0 5vw;
  }
}