#education {
  background: linear-gradient(to right, #B0DBF7, #DCC99D);
}

.edu_banner_box {
  width: 70%;
  max-width: 1500px;
}

.edu_banner_grid {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.edu_banner_grid_school {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 6rem;
}

.edu_banner_grid_info {
  font-size: 1.5rem;
  text-align: center;
}

.edu_list_img {
  width: 100px;
  margin-top: auto;
  padding-top: 10px;
}

#education .info_box.info_box_helper {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .edu_banner_grid {
    flex-direction: column;
  }
}