#aboutme {
  background: linear-gradient(to right, #A7A3CE, #DE89A6);
}

.img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 100px;
}

#aboutme img {
  width: 300px;
  height: auto;
  display: flex;
}

#aboutme .info_box {
  width: auto;
  max-width: 750px;
  margin-left: -100px;
}

.info_box h2 {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  #aboutme img {
    display: none;
  }

  #aboutme .info_box {
    padding: 0 40px;
    margin: 0 40px;
    line-height: 1.3;
  }
}