#healthcentral {
  background: linear-gradient(to right, #EFDCDD, #FAE7DD);
  padding: 3rem 0;
}

#healthcentral .flex_container_row h2 {
  font-size: 2.5rem;
}

#healthcentral .flex_container_row li {
  font-size: 1.8rem;
  line-height: 140%;
}

#healthcentral .flex_container_row {
  max-width: 700px;
}

#healthcentral img {
  align-self: center;
  justify-self: center;
}

@media screen and (max-width: 768px){
  #healthcentral .flex_container_row {
    padding: 0 20px;
  }

  #healthcentral .flex_container_row h2 {
    font-size: 2rem;
  }

  #healthcentral .flex_container_row li {
    font-size: 1.5rem;
  }
}