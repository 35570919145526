#contact {
  background: linear-gradient(to right, #f5f5dc, #ff9999);
}

.social_media_grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 50px;
}

.social_media_item_grid {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.icon {
  padding: 0.5rem;
  border-style: solid;
  border-color: rgb(21, 102, 105);
  border-radius: 100%;
}

.link {
  font-size: 2rem;
  color: rgb(21, 102, 105);
  justify-self: center;
  align-self: center;
}

.contact_image {
  width: 500px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .social_media_item_grid {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact_image {
    display: none;
  }

  #contact a {
    font-size: 1.5rem;
  }
}