nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  height: 50px;
}

.navbar a {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 10px;
}

.navbar a:hover {
  background-color: #f4f4f4;
}

.hamburger {
  display: none;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
}

.navbar.vertical {
  flex-direction: column;
  align-items: flex-start;
}

/* Overlay */
.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.overlay-background.open {
  opacity: 1;
  pointer-events: auto;
}

.overlay-content {
  width: 50%;
  height: 100%;
  background-color: #fff;
  box-shadow: -3px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.overlay-background.open .overlay-content {
  transform: translateX(0);
}

.navbar-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
  background-color: #fff;
}

.navbar-mobile a {
  color: #333;
  text-decoration: none;
  padding: 10px 0px;
  border-radius: 5px;
  margin: 0 10px;
  font-weight: bold;
}


/* If display phone screen, then show hamburger */
@media screen and (max-width: 1100px) {
  .navbar {
    display: none;
  }

  .hamburger {
    display: flex;
    justify-content: flex-end;
    margin: 10px 10px;
    z-index: 2000;
    position: relative;
  }

  .hamburger-icon {
    cursor: pointer;
  }
}