#trailrunner .flex_container_row h2 {
  font-size: 2.5rem;
}

#trailrunner .flex_container_row li {
  font-size: 1.8rem;
  line-height: 140%;
}

#trailrunner .flex_container_row {
  max-width: 60%;
}

#trailrunner img {
  align-self: flex-end;
  justify-items: right;
}

@media (max-width: 768px) {
  #trailrunner .flex_container_row h2 {
    font-size: 2rem;
    margin-top: -50px;
  }

  #trailrunner ul {
    margin-left: -50px;
  }

  #trailrunner .flex_container_row li {
    font-size: 1.2rem;
  }

  #trailrunner img {
    width: 100%;
    height: auto;
  }
}