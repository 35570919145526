#home {
  background: linear-gradient(to right, #F5F5DC, #FF9999);
  padding-top: 100px;
}

.intro-info {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.intro-info * {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

.intro-text {
  font-size: 100px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.intro-info h2 {
  font-size: 30px;
}

.intro-info img {
  width: 300px;
  height: auto;
}

@media (max-width: 768px) {
  #home {
    padding-top: 0;
  }

  .intro-info {
    text-align: center;
  }

  .headshot img {
    width: 300px;
    height: auto;
  }
}