#workexperience {
  background: radial-gradient(#E1CDAC, #EDA791, #FED2C1);
}

#workexperience .flex_container {
  padding: 3rem 0;
}

#workexperience section {
  width: 100vw;
}

#workexperience .info_box {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.w_e_list {
  width: 350px;
  padding: 0px;
  text-align: center;
}

.w_e_info {
  font-size: 1.2rem;
}

.w_e_list_img {
  width: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.m_c {
  width: 50px; 
}

@media (max-width: 768px) {
  #workexperience .info_box {
    justify-content: center;
  }  
}